import React from 'react'
import "./index.css";
export default function ModalContainer({ toggleLoginModal }) {

  return (
    <div className="modal">
      <div className="modalOpen">
        <div className="modalBox">
          <div className='new-and-existing-user-btn'>
            <button className="newUserLoginBtn">
              New User
            </button>
            <button className="existingUserLoginBtn">
              Existing User
            </button>
          </div>
          <p className="close" onClick={toggleLoginModal}>
            X
          </p>
        </div>
      </div>
    </div>
  );
}
