import React from 'react'
import { ConfigProvider, Drawer } from 'antd';
import "./index.css";
import { Link, useNavigate } from 'react-router-dom';

export default function SideHeader({ onClose, isSideHeaderVisible, handleNavigation }) {
  const closeSideHeader = (e) => {
    onClose();
    handleNavigation(e)
  };
  const loginPlug = () => {
    onClose();
  }

  const navigate = useNavigate();

  const handleRequestDemoClick = () => {
    navigate('/request-demo');
};

  return (
    <Drawer placement="right" open={isSideHeaderVisible} onClose={onClose} closeIcon={false}>
      <div className='side-header-logo' style={{ marginTop: "30px", marginLeft: "16px" }}>
        <img
          src={require('../../assets/images/newriggle4.png')}
          alt='Riggle Logo'
        />
      </div>
      <div className="sideheader-navbar">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#f57e3b',
            },
          }}
        >
          <li onClick={closeSideHeader}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={closeSideHeader}>
            <Link to="/about">About</Link>
          </li>
          <li onClick={closeSideHeader}>
            <Link to="/product">Product</Link>
          </li>
          <li onClick={closeSideHeader}>
            <Link to="/career">Careers</Link>
          </li>
          <li onClick={closeSideHeader}>
            <Link to="/help">Help</Link>
          </li>

        </ConfigProvider>
        <div className="sideheader-btn">
        <a href="https://app.riggleapp.in/auth" rel="noreferrer">
            <button className='sideheader-login-btn' onClick={loginPlug}>
              Login
            </button>
          </a>
            <button className='sideheader-register-btn' onClick={handleRequestDemoClick}>
            Request Demo
            </button>
        </div>
      </div>
    </Drawer>
  )
}
