const jobs = [
  {
    id: 10,
    title: "Full Stack Developer",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "Collaborate with business stakeholders and product managers to define product requirements.",
      "Validate architectural decisions, create comprehensive designs, and deliver high-quality, scalable, and secure technical solutions.",
      "Design efficient and resilient software solutions using micro-services architecture.",
      "Develop web apps, services, and integrations by writing clean, scalable, reusable, and optimized code.",
      "Solve complex problems and deliver value to the users with innovative solutions.",
      "Lead features/modules from design to implementation.",
      "Develop documentation throughout the software development life cycle (SDLC).",
      "Test, troubleshoot, debug, and deploy software modules.",
      "Contribute to a team culture that embraces diversity and inclusion.",
    ],
    qualifications: [
      "Bachelor’s and/or master’s degree in Computer Science, Computer Engineering, or related technical discipline.",
      "5+ years of professional software development experience.",
      "Strong analytical capabilities, independent self-learner.",
      "Experience in developing rich user-interfaces and backend services using React, HTML, JavaScript, Python, DevOps, Django, Node.js.",
      "Background in building microservices and REST APIs that can be hosted in Kubernetes or Docker.",
      "Experience with database systems (SQL and NoSQL).",
      "Experience in working with AWS cloud platforms.",
      "Experienced in working with CI/CD pipelines.",
      "Ability to thrive in an agile environment and manage multiple tasks efficiently.",
      "Experience with Unix-based systems and command-line tools.",
      "Experience in IaC and writing and reviewing Terraform scripts.",
    ]
  },
  {
    id: 2,
    title: "Backend Developer",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "As a Backend developer, you will be responsible for writing effective and scalable code using Python and improve functionality of existing systems.",
      "Debug and optimize performance to ensure scalability and reliability with Python test framework tools.",
      "Coordinate with internal and external teams to understand user requirements and provide technical solutions.",
      "Keep up to date with the latest technologies and tools.",
    ],
    qualifications: [
      "A minimum of 1-2 years of experience in Python Development with hands-on experience in Django and Django Rest Framework.",
      "Understanding of web development principles (RESTful APIs, MVC architecture).",
      "Familiarity with relational databases such as MySQL.",
      "Experience with version control systems like Git.",
      "Would be nice to have familiarity with Docker, Celery, Redis, or other related technologies.",
      "Exposure to JavaScript frameworks (React, React Native, etc.).",
    ]
  },
  {
    id: 3,
    title: "Front End Developer",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "Designing and developing responsive web applications using React Framework.",
      "Should have solid experience in React, JavaScript, HTML, and CSS.",
      "Hands-on experience in change management tools like GitHub.",
      "Work closely with backend developers to integrate frontend components with server-side logic.",
      "Ability to write code from scratch and build reusable code for future use.",
      "Ensuring a balance between functional and aesthetic design.",
      "Excellent verbal and written communication skills.",
    ],
    qualifications: [
      "A minimum of 1 year of experience in React and JavaScript.",
      "Experience in Next.js and TypeScript will be beneficial.",
      "Good understanding of RESTful APIs and integration with backend services.",
      "Prior experience working in an Agile team and usage of Jira.",
      "Exposure to DevOps operations.",
    ]
  },
  {
    id: 4,
    title: "React Native Developer",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "You will be required to work with cross-functional teams to define, design, and develop new features.",
      "Designing and maintaining high-performance, reusable UI components and reliable code.",
      "Analyzing designs for efficient development planning.",
      "Identifying and resolving performance bottlenecks.",
    ],
    qualifications: [
      "A minimum of 2+ years of hands-on experience (excluding internships) in JavaScript and TypeScript.",
      "Experience in the React/React Native ecosystem.",
      "Strong problem-solving skills and reasoning ability.",
      "Candidates working with product companies having good user apps with high traffic are most preferred for the job.",
    ]
  },
  {
    id: 5,
    title: "UI UX Designer",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "Cultivates a deep understanding of product UX across platforms and ensures execution with reverence for a cohesive experience.",
      "Works closely and collaboratively with the visual designer and product manager on the team to fulfill the overall product/feature vision.",
      "Manages bandwidth and project pipeline to execute UX design in time with the team’s software roadmap.",
      "Coordinates, executes, and documents user research. Runs user interviews, ethnographic studies, and other research methodologies to understand personas and user cases around upcoming roadmapped features.",
      "Collaboratively brainstorms and evolves feature UX with the product manager and wider team.",
      "Creates extremely detailed user flows, product maps, wireframes, etc., based on requirements from Product Management, and communicates UX clearly to relevant teams.",
      "Builds out multi-version comps for user testing.",
      "Prototypes user experiences and coordinates and runs tests with end users.",
      "Quickly iterates and offers versions based on design critique from peers or from learnings found in user testing or feature response.",
      "Captures all states, edge-cases, 'unhappy paths,' and path logic for visual UI design and engineering teams to build out.",
      "Evaluates, tests, and iterates on current core experiences.",
      "Works with Product Management, Engineering, and Visual UI Design to ensure executed work matches UX vision.",
    ],
    qualifications: [
      "Proven experience as a UI/UX Designer or similar role, with a strong portfolio showcasing user-friendly and aesthetically pleasing designs.",
      "Proficiency in design tools like Figma, Sketch, Adobe XD, or similar software.",
      "Strong understanding of UX principles, information architecture, and user-centered design methodologies.",
      "Experience conducting user research and usability testing.",
      "Knowledge of front-end technologies (HTML, CSS) is a plus.",
      "Excellent communication skills and ability to collaborate with diverse teams.",
      "Detail-oriented, creative, and able to meet deadlines in a fast-paced environment.",
    ]
  },
  {
    id: 6,
    title: "QA Tester",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "Contribute to implementing the overall QA strategy and processes.",
      "Work with product managers and engineers to map out QA use cases for the features being deployed within each sprint.",
      "Engage with team members of other disciplines to ensure your testing accounts for all customer, data, product, and engineering scenarios.",
      "Support QA efforts for regression testing, feature testing, and bug validation.",
      "Support engineers in building test suites.",
      "Identify, document, and track defects using a bug-tracking system (e.g., JIRA, Click Up).",
      "Provide clear, detailed bug reports and feedback to developers and product teams.",
      "Prepare and maintain test documentation, including test plans, test cases, and release notes.",
    ],
    qualifications: [
      "A minimum of 2+ years of work experience as a software tester or QA Tester.",
      "Experience testing web, mobile, or desktop applications.",
      "Familiarity with using tools like JIRA, Click Up, Notion, etc.",
      "Experience with writing test plans, test cases, and detailed bug reports.",
      "Ability to investigate, debug, and triage difficult problems both independently and collectively.",
      "Experience with Git and codebase management.",
      "Highly organized, creative, motivated, and passionate about achieving results.",
    ]
  },
  {
    id: 7,
    title: "Product Management",
    location: "Mumbai, Bhandup West",
    responsibilities: [
      "Assist in writing Product Requirement Documents (PRDs).",
      "Conduct research and competitive analysis.",
      "Drive Riggle’s Go-To-Market (GTM) strategy, social media, and marketing initiatives.",
      "Collaborate with cross-functional teams to enhance our product.",
      "Define, track, and report key metrics to ensure product success.",
      "Participate in customer discovery calls, gather feedback, and contribute to product improvements.",
    ],
    qualifications: [
      "A minimum of 3 years of work experience.",
      "High level of agency and ownership.",
      "Strong analytical and research skills.",
      "Excellent communication and teamwork abilities.",
      "Prior experience in GTM strategies is a plus.",
      "Experience in cross-functional collaboration and leadership is a plus.",
    ]
  },
  
  // Add other jobs similarly
];

export default jobs;
