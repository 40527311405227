import React from 'react';
import './index.css';

const CenteredHoverableComponentBlack = ({data}) => {
  return (
    <div className="centered-hoverable-container-black">
      <div className="hoverable-component-black">{data}</div>
    </div>
  );
};

export default CenteredHoverableComponentBlack;
