import React, { useState } from 'react';
import "./index.css";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ApplicationForm from '../../components/ApplicationForm';
import Header from '../../components/Header';
import SideHeader from '../../components/SideHeader';
import { scroller } from 'react-scroll';
import ModalContainer from '../../components/ModalContainer';
import jobs from "../../components/JobListing/jobs";

const CareersApplication = () => {
  const location = useLocation();
  const { id, title, location: jobLocation } = location.state;

  const [isSideHeaderVisible, setIsSideHeaderVisible] = useState(false);

  const toggleSideBar = () => {
    setIsSideHeaderVisible(!isSideHeaderVisible);
  };

  const [openModalBox, setOpenModalBox] = useState(false);
  const toggleLoginModal = () => {
    setOpenModalBox(!openModalBox);
  };

  function handleNavigation(e) {
    scroller.scrollTo(e?.navId, {
      duration: 10,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: e?.offset
    })
  }

  const jobDetails = jobs.find(job => job.title === title);

  return (
    <div>
      <Header handleNavigation={handleNavigation} toggleSideBar={toggleSideBar} toggleLoginModal={toggleLoginModal} />
      <SideHeader onClose={toggleSideBar} toggleLoginModal={toggleLoginModal} isSideHeaderVisible={isSideHeaderVisible} handleNavigation={handleNavigation} />
      {openModalBox ? (
        <ModalContainer toggleLoginModal={toggleLoginModal} />
      ) : null}
      <div class="sales-associate-container">
        <Helmet>
          <title>Apply for {title} in {jobLocation}</title>
        </Helmet>
        <div class="content">
          <h1>{title}</h1>
          <p>Location : {jobLocation}</p>
        </div>
      </div>
      <div className="responsibilties-and-qualifications">
        <div className="responsibilties-and-qualifications-inner">
        <h2>Responsibilities</h2>
        <ul>
          {jobDetails?.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>

        {/* Qualifications Section */}
        <h2>Qualifications</h2>
        <ul>
          {jobDetails?.qualifications.map((qualification, index) => (
            <li key={index}>{qualification}</li>
          ))}
        </ul>
        </div>
      </div>
      <ApplicationForm jobId={id}/>
    </div>
  );
};

export default CareersApplication;