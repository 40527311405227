import React from 'react';
import PlugProductImage from '../../assets/svg/loadersmall.svg';

const CustomSmallLoader = () => (
  <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src={PlugProductImage} alt="Loader" style={{ animation: 'spin 0.8s infinite' }} />
  </div>
);

export default CustomSmallLoader;
