import React from 'react'
import "./index.css";
import { ReactComponent as JoinUsSvg } from '../../assets/svg/joinusphoto.svg';
import CenteredHoverableComponentBlack from '../CenterHeaderHoverableBlack';

export default function CareerTopComponent() {
  return (
    <div className="career-top-component">
      <div className="left-career-top-component">
        <div className="left-career-top-component-inner">
          <CenteredHoverableComponentBlack data='Join Us' />
          <p>
            At Riggle, we are cosmic pioneers, leading the way in the next generation of seamless online ordering solutions. Our mission is to empower businesses to streamline their procurement processes and elevate their operations to galactic heights.
          </p>
        </div>
        <div class="arrow-gradient-left"></div>
      </div>
      <div className="right-career-top-component">
      <div class="arrow-gradient-right"></div>
        <JoinUsSvg />
      </div>
    </div>
  )
}
