import React from 'react';
import './index.css';
import { ReactComponent as MenuIcon } from '../../assets/svg/hamburger.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header({ toggleSideBar, handleNavigation }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigationClick = (navId, offset) => {
    handleNavigation({ navId, offset });
  };

  const handleRequestDemoClick = () => {
      navigate('/request-demo');
  };


  return (
    <header>
      <div className={`header-nav-inner-container`}>
        <div className='header-logo-container'>
          <Link to="/" className='header-logo' onClick={() => handleNavigationClick("home", -150)}>
            <img
              src={require('../../assets/images/newriggle4.png')}
              alt='Riggle Logo'
            />
          </Link>
        </div>
        <nav className='header-page-nav'>
        <ul>
            <li className={location.pathname === '/' ? 'active-nav' : ''}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === '/about' ? 'active-nav' : ''}>
              <Link to="/about">About</Link>
            </li>
            <li className={location.pathname === '/product' ? 'active-nav' : 'inactive'}>
              <Link to="/product">Product</Link>
            </li>
            {/* <a href='https://blog.riggleapp.in/' target="_blank" rel="noreferrer">
                Blog
              </a> */}
            <li className={location.pathname === '/career' ? 'active-nav' : 'inactive'}>
              <Link to="/career">Careers</Link>
            </li>
            <li className={location.pathname === '/help' ? 'active-nav' : ''}>
              <Link to="/help">Help</Link>
            </li>
          </ul>
        </nav>
        <div className='header-button-container'>
          <a href="https://app.riggleapp.in/auth" rel="noreferrer">
            <button className='header-login-btn'>
              Login
            </button>
          </a>
            <button className='header-register-btn' onClick={handleRequestDemoClick}>
              Request Demo
            </button>
          <div className='reponsive-svg' onClick={toggleSideBar}>
            <MenuIcon className='header-responsive-icon'
            />
          </div>
        </div>
      </div>
    </header>
  );
}
