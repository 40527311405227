import React from 'react'
import "./index.css";
import { ReactComponent as InnovativeEnv } from '../../assets/svg/innovativesvg.svg';
import { ReactComponent as ImpactfulWrk } from '../../assets/svg/impactfulwrk.svg';
import { ReactComponent as CollaborativeCult } from '../../assets/svg/collabcult.svg';
import { ReactComponent as Growthopp } from '../../assets/svg/growth.svg';
import CenteredHoverableComponent from '../CenterHeaderHoverable';

const data = [{
  name: 'Innovative Environment',
  image: <InnovativeEnv />,
  para: 'Join a team of forward-thinkers and visionaries who are passionate about harnessing cutting-edge technology to drive meaningful change in the industry.'
}, {
  name: 'Impactful Work',
  image: <ImpactfulWrk />,
  para: 'At Riggle, your contributions matter. Join us in revolutionizing the way businesses manage their procurement processes, and be a part of shaping the future of B2B SaaS.'
}, {
  name: 'Collaborative Culture',
  image: <CollaborativeCult />,
  para: 'We believe in the power of collaboration. At Riggle, you\'ll work alongside talented individuals who are dedicated to achieving common goals and driving collective success.',
},
{
  name: 'Growth Opportunities',
  image: <Growthopp />,
  para: 'Riggle is committed to your professional development and growth. Whether you\'re just starting your career or looking to take the next step, we provide opportunities for learning, skill development, and career advancement.',
}
]

export default function HiringCards() {
  return (
    <div className='hiring-card-container'>
      <div className='hiring-card-outer'>
        <div className='home-container-heading'>
          <CenteredHoverableComponent data='Why You’ll Love it Here?' />
        </div>
        <div className='hiring-card'>
          {data.map((el) => (
            <div key={el.name} className='hiring-card-item'>
              <div>{el.image}</div>
              <div className='hiring-card-item-inner'>
                <h4>{el.name}</h4>
                <p>
                  {el.para}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}


