import React, { useState } from 'react';
import "./index.css";
import HiringCards from '../../components/HiringCards';
import WeAreHiring from '../../components/WeAreHiring';
import { ReactComponent as Bash } from '../../assets/svg/bash.svg';
import Footer from '../../components/Footer';
import CareerTopComponent from '../../components/CareerTopComponent';
import Header from '../../components/Header';
import { scroller } from 'react-scroll';
import SideHeader from '../../components/SideHeader';
import ModalContainer from '../../components/ModalContainer';
import CenteredHoverableComponent from '../../components/CenterHeaderHoverable';
import { Helmet } from 'react-helmet';

export default function Careers() {

  const [isSideHeaderVisible, setIsSideHeaderVisible] = useState(false);

  const toggleSideBar = () => {
    setIsSideHeaderVisible(!isSideHeaderVisible);
  };

  const [openModalBox, setOpenModalBox] = useState(false);
  const toggleLoginModal = () => {
    setOpenModalBox(!openModalBox);
  };

  function handleNavigation(e) {
    scroller.scrollTo(e?.navId, {
      duration: 10,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: e?.offset
    });
  }

  return (
    <div>
      <Helmet>
        <title>Career | Riggle App</title>
        <meta name="title" content="Career | Riggle App" />
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
        <meta name="author" content="www.riggleapp.in" />
        <meta name="googlebot" content="index, follow" />
        <meta name="geo.placename" content="India" />
        <meta name="directory" content="submission" />
        <meta name="content-language" content="EN-US" />
        <meta name="distribution" content="global" />
        <meta name="revisit-after" content="1 days" />
        <meta name="rating" content="general" />
        <meta name="document-type" content="Public" />
        <meta name="organization" content="Potluck Technologies Pvt Ltd" />
        <meta name="copyright" content="2024-2025 Potluck Technologies Pvt Ltd" />
        <meta name="DC.title" content="Career | Riggle App" />
        <meta name="geo.region" content="MH" />
        <meta name="geo.placename" content="Maharashtra, India" />
        <meta name="geo.position" content="19.1623283 72.9380631" />
        <link rel="publisher" href="https://riggleapp.in/career" />
        
        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="Website" />
        <meta property="og:title" content="Career | Riggle App" />
        <meta property="og:image" content="https://riggleapp.in/static/media/banner2x.fa0d314ce902e72aa54a.webp" />
        <meta property="og:url" content="https://riggleapp.in/career" />
        <meta property="og:site_name" content="Potluck Technologies Pvt Ltd" />
        <meta property="place:location:latitude" content="19.1623283" />
        <meta property="place:location:longitude" content="72.9380631" />
        <meta property="business:contact_data:street_address" content="Lal Bahadur Shastri Marg, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078" />
        <meta property="business:contact_data:locality" content="Bhandup West, Mumbai" />
        <meta property="business:contact_data:postal_code" content="400078" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta property="business:contact_data:email" content="hello@riggleapp.in" />
        <meta property="business:contact_data:phone_number" content="8850244062" />
        <meta property="business:contact_data:website" content="https://riggleapp.in/" />
        <link rel="canonical" href="https://riggleapp.in/career" />
      </Helmet>

      <Header handleNavigation={handleNavigation} toggleSideBar={toggleSideBar} toggleLoginModal={toggleLoginModal} />
      <SideHeader onClose={toggleSideBar} toggleLoginModal={toggleLoginModal} isSideHeaderVisible={isSideHeaderVisible} handleNavigation={handleNavigation} />
      {openModalBox ? (
        <ModalContainer toggleLoginModal={toggleLoginModal} />
      ) : null}
      <CareerTopComponent />
      <HiringCards />
      <div className="bash-container">
        <div className="bash-container-inner">
          <CenteredHoverableComponent data="Our Bash" />
          <p>
            Be part of a Riggle that cherishes every moment—whether it's festivals, or birthdays,
            we make sure to celebrate them together.
          </p>
          <Bash />
        </div>
      </div>
      <WeAreHiring />
      <Footer />
    </div>
  );
}
