import React from 'react';
import "./index.css";
import { Space } from 'antd';
import RiggleLogo from '../../assets/images/newriggle4.png';
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import facebook from '../../assets/svg/facebook.svg';
import instagram from '../../assets/svg/instagram.svg';
import twitter from '../../assets/svg/twitter.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import FooterBackground from '../../assets/svg/footer.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Footer() {
  return (
    <footer>
      <div className='inner-footer'>
        <section className='footer-company-links'>
          <div className='footer-column-first'>
            <div className='riggle-footer-logo'>
              <img src={RiggleLogo} alt='Riggle Logo' />
            </div>
            <div className='riggle-footer-headline'>
              <p>Grow your business with Riggle</p>
            </div>
            <div className='riggle-footer-headline'>
              <a href='https://blog.riggleapp.in/' target="_blank" rel="noreferrer">
                Blog
              </a>
            </div>
            <div className='follow-us-on-container'>
              <p className='riggle-footer-headline'>Follow us on</p>
              <Space size='middle' style={{ paddingLeft: '8px' }}>
                <a href='https://www.facebook.com/profile.php?id=100092731064840' target="_blank" rel="noreferrer">
                  <img src={facebook} alt='facebook' className='follow-us-on-icons' />
                </a>
                <a href='https://www.instagram.com/rigglekaro?igsh=YzVkODRmOTdmMw==' target="_blank" rel="noreferrer">
                  <img src={instagram} alt='instagram' className='follow-us-on-icons' />
                </a>
                <a href='https://twitter.com/RiggleApp' target="_blank" rel="noreferrer">
                  <img src={twitter} alt='twitter' className='follow-us-on-icons' />
                </a>
                <a href='https://www.linkedin.com/company/riggle-app/' target="_blank" rel="noreferrer">
                  <img src={linkedin} alt='linkedin' className='follow-us-on-icons' />
                </a>
              </Space>
            </div>
          </div>
          <div className='footer-column'>
            <h5>Apps</h5>
            <a href='https://play.google.com/store/apps/details?id=com.riggle' target="_blank" rel="noreferrer">Retailer</a>
            <a href='https://play.google.com/store/apps/details?id=com.riggle' target="_blank" rel="noreferrer">Partner</a>
            <a href='https://play.google.com/store/apps/details?id=com.rk_tech.riggle_runner' target="_blank" rel="noreferrer">R Ops</a>
            <a href='https://play.google.com/store/apps/details?id=com.rk.riggle_sales' target="_blank" rel="noreferrer">Sales</a>
          </div>
          <div className='footer-column'>
            <h5>Resources</h5>
            <a href="/terms-and-conditions">Terms & Conditions</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/refund-policy">Refund Policy</a>
            <a href="/shipping-policy">Shipping Policy</a>
            <a href="/feedback">Feedback</a>
          </div>
          <div className='footer-column'>
            <h5>Contact Us</h5>
            <a href="mailto:hello@riggleapp.in">
              <span>
                <AiOutlineMail fontSize={18} />
              </span>
              hello@riggleapp.in
            </a>
            <a href="tel:+918850244062" target="_blank" rel="noreferrer">
              <span>
                <IoCallOutline fontSize={18} />
              </span>
              8850244062
            </a>
            <p style={{ width:'250px'}}>
              <span>
                <IoLocationOutline fontSize={18}/>
              </span>
              1st Floor, 102, Srishti Square, Lal Bahadur Shastri Marg, Near Pixeltech Security Pvt Ltd, Bhandup West, Mumbai 400078, Maharashtra
            </p>
          </div>
        </section>
        <section className='footer-bottom'>
          <p>All rights reserved Potluck Technologies Pvt Ltd. &copy; 2024</p>
        </section>
      </div>
      <div className="footer-background-container">
        <LazyLoadImage
          src={FooterBackground}
          alt="about us"
          className="footer-us-background"
        />
      </div>
    </footer>
  )
}



