import React from 'react';
import './index.css';

const CenteredHoverableComponent = ({data}) => {
  return (
    <div className="centered-hoverable-container">
      <div className="hoverable-component">{data}</div>
    </div>
  );
};

export default CenteredHoverableComponent;
