import React from 'react'
import "./index.css";
import CenteredHoverableComponent from '../CenterHeaderHoverable';
import JobListings from '../JobListing';

export default function WeAreHiring() {
  return (
    <div className='we-are-hiring'>
      <CenteredHoverableComponent data="We're Hiring" />
      <p>
      Join us in our mission to transform the procurement landscape. Check out our current job openings below:
      </p>
      <JobListings/>
    </div>
  )
}
