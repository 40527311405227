import React, { useState } from 'react';
import "./index.css";
import { Form, Input, Button, Checkbox, Alert, Row, Col, Upload, message } from 'antd';
import { FiUpload } from "react-icons/fi";

const JobApplicationForm = ({ jobId}) => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleUploadChange = (info) => {
    const { status } = info.file;
    
    // Convert fileList to an array
    let newFileList = [...info.fileList];
  
    // Limit the number of files to 1
    newFileList = newFileList.slice(-1);
  
    // Update the fileList state
    setFileList(newFileList);
  
    if (status === 'removed') {
      form.setFieldsValue({ resume: null });
      message.success(`${info.file.name} file removed successfully.`);
    } else {
      // Store the file in the form
      form.setFieldsValue({ resume: info.file.originFileObj });
      message.success(`${info.file.name} file selected successfully.`);
    }
  };
  

  const handleSubmit = async (values) => {
    if (!values.notRobot) {
      message.error("Please confirm you're not a robot.");
      return;
    }
  
    const apiUrl = `https://stag.api.riggleapp.in/api/v1/user/careers/`;
  
    const formDataToSend = new FormData();
    formDataToSend.append('first_name', values.firstName);
    formDataToSend.append('last_name', values.lastName);
    formDataToSend.append('email', values.email);
    formDataToSend.append('mobile', values.phone);
    formDataToSend.append('type', 'careers');
    formDataToSend.append('job_id', jobId);  
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (file.originFileObj instanceof File) {
        formDataToSend.append('resume', file.originFileObj, file.name);
      }
    }
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'x-app-name': 'play',
        },
        body: formDataToSend,
      });
  
      if (response.ok) {
        form.resetFields();
        setFileList([]); // Clear the fileList state
        setSubmitted(true);
        message.success('Application submitted successfully!');
      } else {
        const errorData = await response.json();
        message.error(`Submission failed: ${errorData.message || 'Please try again.'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div style={{ maxWidth: '90vw', margin: 'auto', padding: '20px' }}>
      <p style={{ fontSize: '18px', color: '#FA8C16', marginBottom: '20px' }}>Submit your application</p>

      {submitted && (
        <Alert message="Your application has been submitted successfully!" type="success" showIcon style={{ marginBottom: '20px' }} />
      )}

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
  name="resume"
  valuePropName="file"
  getValueFromEvent={(e) => e && e.fileList}
>
  <Upload
    accept=".pdf,.doc,.docx"
    fileList={fileList}
    beforeUpload={() => false}
    onChange={handleUploadChange}
    onRemove={() => {
      form.setFieldsValue({ resume: null });
      setFileList([]);
    }}
  >
    <Button icon={<FiUpload />} 
    style={{ 
      marginBottom: '20px', 
      color: '#FA8C16', 
      borderColor: '#FA8C16',
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems: 'center',
    }}
      >
      Upload Resume
    </Button>
  </Upload>
</Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First name*"
              name="firstName"
              rules={[{ required: true, message: 'Please enter your first name' }]}
            >
              <Input style={{ padding: '10px' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last name*"
              name="lastName"
              rules={[{ required: true, message: 'Please enter your last name' }]}
            >
              <Input style={{ padding: '10px' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Email*"
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input style={{ padding: '10px' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone number*"
              name="phone"
              rules={[{ required: true, message: 'Please enter your phone number' }]}
            >
              <Input style={{ padding: '10px' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="notRobot" valuePropName="checked">
          <Checkbox className="custom-checkbox">
            I'm not a robot
          </Checkbox>
        </Form.Item>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button type="primary" htmlType="submit" style={{ backgroundColor: '#F57E3B' }}>
            Submit Application
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default JobApplicationForm;