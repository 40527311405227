import React from 'react';
import jobsData from './jobs'; 
import "./index.css";
import { useNavigate } from 'react-router-dom';

const JobListing = ({ id, title, location }) => {
  const navigate = useNavigate();

  const handleApply = () => {
    const formattedTitle = title.toLowerCase().replace(/ /g, '-');
    navigate(`/career/${id}/${formattedTitle}`, { state: { id, title, location } });
  };

  return (
    <div className="job-listing">
      <div className="job-info">
        <h2 className="job-title">{title}</h2>
        <p className="job-location">[Location: {location}]</p>
      </div>
      <button className="apply-button" onClick={handleApply}>Apply Now</button>
    </div>
  );
};

const JobListings = () => {
  return (
    <div className="job-listings">
      {jobsData.map((job) => (
        <JobListing key={job.id} id={job.id} title={job.title} location={job.location} />
      ))}
    </div>
  );
};

export default JobListings;